<template>
  <div class="index-container">
    <div class="main-content">
      <Header />
      <p class="slogan">{{ slogan }}</p>
      <ul class="house-type">
        <li
          @click="currentActive = tmp.id"
          :class="{ 'li-active': currentActive === tmp.id }"
          v-for="tmp in list"
          :key="tmp.id"
        >
          {{ tmp.label }}
        </li>
      </ul>
      <el-input
        class="search-input"
        :placeholder="placeholder"
        v-model="search"
      >
        <i @click="doSearch" class="el-icon-search" slot="suffix"></i>
      </el-input>
    </div>
    <div class="house-wrapper">
      <p class="title">二手好房</p>
      <div class="title-wrapper">
        <h4 class="sub-title">好房源那么多，我们为你精选</h4>
        <h4 class="sub-more" @click="checkMore">更多好房</h4>
      </div>
      <div class="house-list">
        <el-row :gutter="20">
          <el-col :span="8" v-for="item in dataList" :key="item.houseId">
            <div class="house-item" @click="doCheckDetail(item.houseId)">
              <img :src="item.coverImg" alt="" />
              <h3>{{ item.title }}</h3>
              <h3>{{ item.community }}</h3>
              <h3>
                <span>{{ item.unitType }}.{{ item.rentArea }}平米</span>
                <span style="color: red">{{ item.sellPrice / 10000 }}万</span>
              </h3>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="footer">
        <span style="margin-right: 20px">ICP备案：苏ICP备2021003690号-1</span>
        <img
          style="vertical-align: middle; margin-right: 5px"
          src="../assets/gongan.png"
          alt=""
        />
        <span style="margin-right: 20px">苏公网安备32010602010867</span>
        <span>客服电话：18551643873</span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  name: "Index",
  data() {
    return {
      slogan: "家是我们每个人最温暖的港湾",
      search: "",
      placeholder: "请输入区域、商圈或小区名开始找房",
      currentActive: 1,
      list: [
        { id: 1, label: "找二手房" },
        { id: 2, label: "找租房" },
        // { id: 3, label: "找中介" },
      ],
      params: {
        areaInfo: "",
        houseType: 1,
      },
      dataList: [],
    };
  },
  components: { Header },
  mounted() {
    this.doFindHouse();
  },
  methods: {
    doCheckDetail(houseId) {
      this.$router.push({ path: "house-detail", query: { houseId, type: 1 } });
    },
    checkMore() {
      this.$router.push({
        path: "house-list",
        query: { active: 1, search: this.search },
      });
    },
    doFindHouse() {
      this.$request
        .post("/api/house/queryHouseList", this.params)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.data.rows;
            this.dataList = this.dataList.splice(0, 3);
          } else {
            this.dataList = [];
          }
        });
    },
    doSearch() {
      this.$router.push({
        path: "house-list",
        query: { active: this.currentActive, search: this.search },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable.scss";
.index-container {
  background: url("../assets/bg_index.jpg") no-repeat center;
  min-width: 1200px;
  height: 600px;
  .main-content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    z-index: 9999999;
    .slogan {
      font-weight: bold;
      font-size: 52px;
      color: $mainColor;
    }
    .house-type {
      padding: 15px 25%;
      text-align: left;
    }
    .house-type li {
      font-size: 24px;
      font-weight: 400;
      margin: 0 20px;
      display: inline-block;
      cursor: pointer;
    }
    .li-active {
      color: $mainColor;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
      }
    }
  }
  .house-wrapper {
    width: 1200px;
    margin: 100px auto;
    .title {
      font-size: 42px;
      font-weight: bold;
    }
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .sub-title {
        font-size: 16px;
        color: gray;
      }
      .sub-more {
        color: $mainColor;
        cursor: pointer;
      }
    }
    .house-list {
      .house-item {
        cursor: pointer;
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 5px;
        }
        h3 {
          margin: 5px 0;
          span {
            font-weight: 100;
            font-size: 16px;
          }
          &:nth-of-type(3) {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
.search-input {
  width: 60%;
  .el-input__inner {
    font-size: 18px;
    height: 60px;
    border-radius: 0;
    border: none;
  }
  .el-input__suffix {
    font-size: 28px;
    line-height: 60px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.el-carousel__container {
  height: 600px;
}
</style>
